import React from "react"
import styled from "styled-components";
import PropTypes from "prop-types"

import "./layout.css"

const BG = styled.div `
  background: rgba(163,208,255,.1);
  min-height: 100vh;
  @media (min-width: 1200px) {
    padding-top: 20px;
  }
  @media (max-width: 640px) {
    font-size: 85%;
  }
`;
const Wrapper = styled.div `
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
  background: white;

  @media (max-width: 640px) {
    padding: 20px;
  }
`;
const Footer = styled.div `
  padding: 40px;
  text-align: center;
  @media (max-width: 640px) {
    padding: 20px;
  }
`;
const Name = styled.span `
  color: #555;
  font-family: 'Fredoka One', Helvetica Neue, sans-serif;
`

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql `
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <BG>
      <Wrapper>
        {children}
      </Wrapper>
      <Footer>
        <div style={{ fontSize: "48px" }}><span role="img" aria-label="beluga">🎷🐋</span></div>
        <div><Name>beluga</Name> is built by <b><a href="https://rachelbinx.com/">rachel binx</a></b></div>
      </Footer> 
    </BG>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout